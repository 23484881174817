import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from 'gatsby-source-datocms'

function SEO() {
  const data = useStaticQuery(
    graphql`
      query SEOQuery {
        datoCmsSite {
          globalSeo {
            siteName
            titleSuffix
            twitterAccount
            facebookPageUrl
            fallbackSeo {
              description
              title
            }
          }
          faviconMetaTags {
            tags
          }
        }
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )

  return (
    <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
      <html lang="pl"/>
      <title>{data.datoCmsSite.globalSeo.siteName}</title>
      <meta name="description" content={data.datoCmsSite.globalSeo.fallbackSeo.description}/>
      <meta name="keywords" content=""/>
      <meta name="author" content={data.site.siteMetadata.author}/>
    </HelmetDatoCms>
  )
}

export default SEO
